import React, { useEffect, useState } from "react";
import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";
import BlogGrid from "../components/Blog/BlogGrid";
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";
import BlogData from "../components/Blog/BlogData";
import { useHistory, useLocation } from "react-router-dom";

const BlogGridPage = () => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);

    const initialPage = parseInt(queryParams.get("page")) || 1;

    const [currentPage, setCurrentPage] = useState(initialPage);
    const [totalPages, setTotalPages] = useState(0);
    const [blogList, setBlogList] = useState([]);

    const blogsPerPage = 10;

    const handleCurrentPage = (crrPage) => {
        setCurrentPage(crrPage);
        queryParams.set("page", crrPage);
        history.push(`?${queryParams.toString()}`);
        setBlogList([]);
    };

    useEffect(() => {
        setTotalPages(Math.ceil(BlogData.Blogs.length / blogsPerPage));
    }, [BlogData]);

    useEffect(() => {
        const startIndex = (currentPage - 1) * blogsPerPage;
        const endIndex = startIndex + blogsPerPage;

        const filteredBlogs = BlogData.Blogs.slice(startIndex, endIndex);

        setBlogList(filteredBlogs);

        if (queryParams.get("page")) {
            window.scrollTo({ top: 350, behavior: "smooth" });
        }
    }, [currentPage]);

    return (
        <div className="body_wrapper">
            <CustomNavbar
                slogo="sticky_logo"
                cClass="custom_container p0"
                mClass="menu_four"
                nClass="w_menu"
            />
            <Breadcrumb
                breadcrumbClass="breadcrumb_area"
                imgName="breadcrumb/banner_bg.png"
                Ptitle="Blogs"
                Pdescription="Why I say old chap that is spiffing off his nut arse pear shaped plastered Jeffrey bodge barney some dodgy.!!"
            />
            <BlogGrid
                BlogData={BlogData}
                totalPages={totalPages}
                handleCurrentPage={handleCurrentPage}
                currentPage={currentPage}
                blogList={blogList}
            />
            <FooterTwo FooterData={FooterData} />
        </div>
    );
};
export default BlogGridPage;
