import React, { useContext } from "react";
import Blogrightsidebar from "./Blogrightsidebar";
import BlogGridItem from "./BlogGridItem";
import ServiceData from "../Service/ServiceData";
import { Link } from "react-router-dom";

const BlogGrid = ({
    BlogData,
    totalPages,
    currentPage,
    handleCurrentPage,
    blogList,
}) => {
    return (
        <section className="blog_area_two sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 blog_grid_info">
                        <div className="row">
                            {blogList.map((blog) => {
                                return (
                                    <BlogGridItem
                                        date={blog.date}
                                        image={blog.image}
                                        Title={blog.title}
                                        description={blog.excerpt}
                                        body={blog.body}
                                        slug={blog.slug}
                                        btn="Read More"
                                        // comment="3"
                                        key={blog.id}
                                    />
                                );
                            })}
                        </div>
                        <ul className="list-unstyled page-numbers shop_page_number text-left mt_30">
                            {Array.from({ length: totalPages }, (_, index) => {
                                const indexIncrement = index + 1;
                                return (
                                    <li>
                                        <span
                                            aria-current="page"
                                            className={`page-numbers ${
                                                currentPage === indexIncrement
                                                    ? "current"
                                                    : ""
                                            }`}
                                            onClick={() =>
                                                handleCurrentPage(
                                                    indexIncrement
                                                )
                                            }
                                        >
                                            {indexIncrement}
                                        </span>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="col-lg-4">
                        <Blogrightsidebar BlogData={BlogData} />
                    </div>
                </div>
            </div>
        </section>
    );
};
export default BlogGrid;
